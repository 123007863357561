<template>
  <div>
    <query-form
      ref="queryRef"
      :query-form.sync="queryMerchantForm"
      :form="form"
    >
      <template v-slot:buttons>
        <b-button
          variant="primary"
          class="mr-1"
          @click="search"
        >Search</b-button>
        <b-button
          variant="secondary"
          class="mr-1"
          @click="reset"
        >Reset</b-button>
      </template>
    </query-form>
    <table-list
      :table-data.sync="tableData"
      :list.sync="tableList"
      :page-num.sync="currentPage"
      :total-list.sync="totalList"
    />
  </div>
</template>
<script>
import {
  defineComponent, ref, getCurrentInstance, watch,
} from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    // 表单
    const queryMerchantForm = ref({})
    const form = ref([
      {
        label: 'Merchant ID', itype: 'input', imodel: 'merchantId', placeholder: 'Enter Merchant ID...',
      },
      {
        label: 'Merchant Name', itype: 'input', imodel: 'merchantName', placeholder: 'Enter Merchant Name...',
      },
      {
        label: 'Registration Date', itype: 'datetime', imodel: 'registrationDate', placeholder: 'Date', md: 4,
      },
      {
        label: 'Status', itype: 'select', imodel: 'status', placeholder: '', Options: [{ key: 'Active', value: 0 }, { key: 'Inactive', value: 1 }], labelKey: 'key', labelValue: 'value', defaultSelected: { key: 'Active', value: 0 }, class: 'mt-1',
      },
      {
        label: 'KYC Status', itype: 'select', imodel: 'kycStatus', placeholder: '', Options: [{ key: 'Waiting Approval', value: 0 }, { key: 'Approved', value: 1 }, { key: 'Rejected', value: 2 }], labelKey: 'key', labelValue: 'value', defaultSelected: { key: 'Waiting Approval', value: 0 }, class: 'mt-1',
      },
    ])
    // 列表
    const currentPage = ref(1)
    const tableList = ref([
      {
        merchant_id: 'HFHDJFJIUR475638', merchant_name: 'Test123', registration_date: '1685603463', status: 'Active', kyc_status: 'Verified',
      },
    ])
    const totalList = ref(0)
    const callback = (type, data) => {
      if (type === 'lock') {
        proxy.$swal({
          title: 'Password Reset',
          html: "<p class='text-left'>If you continue the merchant will have to reset their password.</p><p class='text-left'>Are you sure you wish to continue?</p>",
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonText: 'Continue',
        }).then(result => {
          if (!result.value) {
            // proxy.$showLoading()
            // const res = await proxy.$api.merchantUserGaUnbound({ merchant_id: data.merchant_id }).catch(() => { proxy.$hideLoading() })
            // proxy.$hideLoading()
            // if (!res) { return }
            // const { code, data, message } = res.data
            // if (code === 200) {
            //   console.log(data)
            // } else {
            //   proxy.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: 'Notification',
            //       icon: 'BellIcon',
            //       text: `${message}`,
            //       variant: 'danger',
            //     },
            //   }, {
            //     position: 'top-center',
            //   })
            // }
          }
        })
      } else if (type === 'googleAuthenticator') {
        proxy.$swal({
          title: 'Google Authenticator Binding',
          html: "<p class='text-left'>Cancellation of Google Authenticator binding will result in the merchant having to re-bind their merchant platform account with the Google Authenticator.</p><p class='text-left'>Are you sure you wish to continue?</p>",
          icon: 'info',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonText: 'Continue',
        }).then(result => {
          if (!result.value) {
            // proxy.$showLoading()
            // const res = await proxy.$api.merchantUserResetPassword({ merchant_id: data.merchant_id }).catch(() => { proxy.$hideLoading() })
            // proxy.$hideLoading()
            // if (!res) { return }
            // const { code, data, message } = res.data
            // if (code === 200) {
            //   console.log(data)
            // } else {
            //   proxy.$toast({
            //     component: ToastificationContent,
            //     props: {
            //       title: 'Notification',
            //       icon: 'BellIcon',
            //       text: `${message}`,
            //       variant: 'danger',
            //     },
            //   }, {
            //     position: 'top-center',
            //   })
            // }
          }
        })
      }
    }
    const tableData = ref({
      tableColumns: [
        { key: 'merchant_id', label: 'MERCHANT ID' },
        { key: 'merchant_name', label: 'MERCHANT NAME' },
        { key: 'registration_date', label: 'REGISTRATION DATE' },
        { key: 'status', label: 'STATUS' },
        { key: 'kyc_status', label: 'KYC STATUS' },
        { key: 'security', label: 'SECURITY' },
      ],
      templates: [
        { key: 'registration_date', tType: 'datetime' },
        { key: 'merchant_id', tType: 'merchant_merchant_id' },
        { key: 'security', tType: 'merchant_security', callback },
      ],
    })
    // watch([timeZone], value => { console.log('activity时区', value[0]); timeRange.value = [] })
    const reset = () => {
      proxy.$refs.queryRef.reset()
    }
    const getParams = () => {
      const newParams = {
        page_no: currentPage.value,
        filter: {
          merchant_id: '', // 商户ID string
          merchant_sn: '', // 商户SN string
          merchant_status: 0, // 商户状态：1启用 2禁用 number
          kyc_status: 0, // 商户状态：3处理中 4通过 5拒绝 number
          registration_at: { // 注册时间
            begin: null, // 开始时间戳
            end: null, // 结束时间戳
          },
        },
      }
      if (queryMerchantForm.value.merchantId) {
        newParams.filter.merchant_id = queryMerchantForm.value.merchantId
      }
      if (queryMerchantForm.value.merchantName) {
        newParams.filter.merchant_sn = queryMerchantForm.value.merchantName
      }
      if (queryMerchantForm.value.status) {
        newParams.filter.merchant_status = queryMerchantForm.value.merchantId
      }
      if (queryMerchantForm.value.kycStatus) {
        newParams.filter.kyc_status = queryMerchantForm.value.merchantId
      }
      if (queryMerchantForm.value.registrationDate && queryMerchantForm.value.registrationDate.length > 0) {
        [newParams.filter.registration_at.begin, newParams.filter.registration_at.end] = queryMerchantForm.value.registrationDate
      }
      return newParams
    }
    const fetchMerchantList = async () => {
      const newParams = getParams()
      console.log('newParams', newParams)
      proxy.$showLoading()
      const res = await proxy.$api.fetchMerchantList(newParams).catch(() => { proxy.$hideLoading() })
      proxy.$hideLoading()
      if (!res) { return }
      const { code, data, message } = res.data
      if (code === 200) {
        const { pagination, list } = data
        tableList.value = list
        totalList.value = pagination.total_count
      } else {
        proxy.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: `${message}`,
            variant: 'danger',
          },
        }, {
          position: 'top-center',
        })
      }
    }
    const search = async () => {
      currentPage.value = 1
      await fetchMerchantList()
    }
    watch([currentPage], async () => { await fetchMerchantList() })
    return {
      queryMerchantForm,
      tableData,
      tableList,
      currentPage,
      totalList,

      // func
      search,
      reset,
      form,
    }
  },
  components: {
    BButton,
    // BCard, BForm, BRow, BCol, BInputGroup, BFormInput, BButton, BTable, BPagination, BInputGroupPrepend, BDropdown, BDropdownItem, DatePicker,
  },
})
</script>
